import React from "react";
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded";

import "styles/atoms.scss";

const Loading = () => {
    return (
        <div id="bgLoader">
            <HourglassEmptyRoundedIcon htmlColor={"#ffcd00"} />
        </div>
    );
};
export default Loading;
